import { useTranslation } from 'react-i18next';

import { useResolutions } from '@hooks/useResolutions';

import { IDs } from '@extra/IDs';

import logoImg from '@assets/images/nav/logo.svg';
import mailImg from '@assets/images/nav/mail.svg';
import phoneImg from '@assets/images/footer/phone.svg';
import locationImg from '@assets/images/footer/location.svg';
import dubaiMapImg from '@assets/images/footer/footerDubaiMap.png';
import { ReactComponent as InstagramIcon } from '@assets/images/nav/instagram.svg';

import './index.scss';

export const Footer = ({ scrollToTop }) => {
  const { t } = useTranslation();
  const { windowWidth } = useResolutions();

  return (
    <footer className="Footer" id={IDs.CONTACT_US}>
      <div className="contactsBackground">Contacts</div>

      <img className="logoImg" src={logoImg} onClick={scrollToTop} alt="Logo" />

      <div className="container">
        <a
          href="https://maps.app.goo.gl/jd4kGuEbVmfTu7wx6"
          target="_blank"
          rel="noreferrer"
        >
          <img className="dubaiMapImg" src={dubaiMapImg} alt="Map" />
        </a>

        <div className="contactsWrap">
          <div className="title">{t('footer.Contacts')}</div>

          <div className="infoItem">
            <img className="icon" src={phoneImg} alt="Phone" />

            <a className="text" href="tel:+971585599246">
              +971-58-5599246
            </a>
          </div>

          <div className="infoItem">
            <img className="icon" src={mailImg} alt="Mail" />

            <a className="text" href="mailto:mysterymotion.show@gmail.com">
              mysterymotion.show@gmail.com
            </a>
          </div>
        </div>

        {windowWidth <= 710 ? (
          <div className="locationAndFollowMobileWrap">
            <div className="locationWrap">
              <div className="title">{t('footer.Location')}</div>

              <div className="infoItem">
                <img className="icon" src={locationImg} alt="Point" />

                <a
                  className="text"
                  href="https://maps.app.goo.gl/jd4kGuEbVmfTu7wx6"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dubai
                </a>
              </div>
            </div>

            <div className="followWrap">
              <div className="title">{t('footer.Follow')}</div>

              <a
                href="https://www.instagram.com/mystery_motion/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="instagramIconContainer">
                  <InstagramIcon />
                </div>
              </a>
            </div>
          </div>
        ) : (
          <>
            <div className="locationWrap">
              <div className="title">{t('footer.Location')}</div>

              <div className="infoItem">
                <img className="icon" src={locationImg} alt="Point" />

                <a
                  className="text"
                  href="https://maps.app.goo.gl/jd4kGuEbVmfTu7wx6"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dubai
                </a>
              </div>
            </div>

            <div className="followWrap">
              <div className="title">{t('footer.Follow')}</div>

              <a
                href="https://www.instagram.com/mystery_motion/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="instagramIconContainer">
                  <InstagramIcon />
                </div>
              </a>
            </div>
          </>
        )}
      </div>
    </footer>
  );
};
