import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';

import video1 from '@assets/videos/slider/1.mp4';
import video2 from '@assets/videos/slider/2.mp4';
import video3 from '@assets/videos/slider/3.mp4';
import video4 from '@assets/videos/slider/4.mp4';
import cover1 from '@assets/images/slider/1.jpg';
import cover2 from '@assets/images/slider/2.jpg';
import cover3 from '@assets/images/slider/3.jpg';
import cover4 from '@assets/images/slider/4.jpg';

import { IDs } from '@extra/IDs';
import { Controls } from '@sharedComponents/Controls';

import { coverflowEffect } from '@components/Slider/extra/coverflowEffect';

import soundOn from '@assets/images/slider/soundOn.svg';
import soundOff from '@assets/images/slider/soundOff.svg';

import './index.scss';

const videos = [
  {
    src: video1,
    cover: cover1,
  },
  {
    src: video2,
    cover: cover2,
  },
  {
    src: video3,
    cover: cover3,
  },
  {
    src: video4,
    cover: cover4,
  },
];

export const Slider = () => {
  const sliderRef = useRef(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const itemsRef = useRef([]);
  const [isMuted, setIsMuted] = useState(true);
  const { t } = useTranslation();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (itemsRef?.current?.length && typeof currentVideoIndex === 'number') {
      itemsRef?.current?.forEach(el => el?.pause());

      setTimeout(() => {
        itemsRef?.current?.[currentVideoIndex]?.play();
      }, 0);
    }
  }, [itemsRef, currentVideoIndex]);

  const toggleIsMuted = () => setIsMuted(prev => !prev);

  return (
    <header className="Slider" id={IDs.HOME}>
      <div className="header">
        <div>{t('slider.WelcomeToTheWorld')}</div>

        <div className="h1Container">
          <h1>
            {t('slider.OfAFascinating')} <span>{t('slider.SHOW')}</span>
          </h1>
        </div>
      </div>

      <div className="sliderContainer">
        <div className="customSliderContainer">
          <Swiper
            grabCursor
            ref={sliderRef}
            effect="coverflow"
            centeredSlides
            slidesPerView={3}
            coverflowEffect={coverflowEffect}
            modules={[EffectCoverflow]}
            loop
          >
            {videos.map((el, i) => (
              <SwiperSlide key={i} className="customSlideContainer">
                {({ isActive }) => {
                  if (isActive) {
                    setCurrentVideoIndex(i);
                  }

                  const isActiveSlide = i === currentVideoIndex;

                  return (
                    <div className="videoContainer">
                      <video
                        key={el.cover}
                        muted={isMuted}
                        playsInline
                        loop
                        ref={el => (itemsRef.current[i] = el)}
                        poster={el.cover}
                        className={`${isActiveSlide ? 'activeCover' : 'inactiveCover'}`}
                      >
                        <source src={el.src} type="video/mp4" />
                      </video>
                    </div>
                  );
                }}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="muteContainer">
        <div onClick={toggleIsMuted} className="mute">
          {isMuted ? (
            <img src={soundOff} alt="Sound On" />
          ) : (
            <img src={soundOn} alt="Sound On" />
          )}
        </div>
      </div>

      <div className="controlsContainer">
        <Controls
          array={videos}
          currentIndex={currentVideoIndex}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      </div>
    </header>
  );
};
