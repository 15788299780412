import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { GridPhotos } from './components/GridPhotos';
import { GridPhotosMore } from './components/GridPhotosMore';

import { IDs } from '@extra/IDs';

import { ReactComponent as ChevronIcon } from '@assets/images/gallery/chevronDown.svg';

import './index.scss';

export const Gallery = ({
  activeFullScreenPhotoIndex,
  setActiveFullScreenPhotoIndex,
}) => {
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (typeof activeFullScreenPhotoIndex === 'number') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [activeFullScreenPhotoIndex]);

  return (
    <div className="Gallery" id={IDs.GALLERY}>
      <div className="backgroundBlur" />

      <div className="galleryBackground">Gallery</div>

      <div className="gallery">
        <div>{t('gallery.GALLERY')}</div>

        <div>{t('aboutUs.MYSTERY')}</div>
      </div>

      <Fade className="u-non-blurred">
        <LazyLoadComponent>
          <GridPhotos
            setActiveFullScreenPhotoIndex={setActiveFullScreenPhotoIndex}
          />
        </LazyLoadComponent>
        {showMore && (
          <LazyLoadComponent>
            <GridPhotosMore
              setActiveFullScreenPhotoIndex={setActiveFullScreenPhotoIndex}
            />
          </LazyLoadComponent>
        )}
      </Fade>

      <div className="moreButtonContainer u-non-blurred">
        <button onClick={() => setShowMore(prev => !prev)}>
          <span>{showMore ? t('gallery.Less') : t('gallery.More')}</span>

          <ChevronIcon
            className={`chevronIcon ${showMore ? 'chevronIconRotate' : ''}`}
          />
        </button>
      </div>
    </div>
  );
};
