import nextArrow from '@assets/images/slider/nextArrow.svg';
import prevArrow from '@assets/images/slider/prevArrow.svg';

import './index.scss';

export const Controls = ({ handlePrev, currentIndex, handleNext, array }) => (
  <div className="Controls">
    <div className="prev" onClick={handlePrev}>
      <img src={prevArrow} alt="Previous" />
    </div>

    <div className="dotsContainer">
      <div className="dots">
        {Array.from(Array(array.length), (el, i) => (
          <div key={i} className={`dot ${i === currentIndex && 'activeDot'}`} />
        ))}
      </div>

      <div className="next" onClick={handleNext}>
        <img src={nextArrow} alt="Next" />
      </div>
    </div>
  </div>
);
