import { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

import language from '@assets/images/nav/language.svg';

import './index.scss';

class _ChangeLanguageDesktop extends Component {
  handleClickOutside = () => this.props.setIsChangeLanguagePopupVisible(false);

  render() {
    const {
      setIsChangeLanguagePopupVisible,
      checkCurrentLanguage,
      isChangeLanguagePopupVisible,
      languages,
    } = this.props;

    return (
      <div
        className="ChangeLanguageDesktop"
        onClick={() => setIsChangeLanguagePopupVisible(prev => !prev)}
      >
        {checkCurrentLanguage()}

        <img src={language} alt="Language" />

        {isChangeLanguagePopupVisible && (
          <div className="languages">
            {languages.map(el => (
              <div
                key={el.title}
                className={`lang${el.isCurrent ? ' activeLanguage' : ' inactiveLanguage'}`}
                onClick={el.onClick}
              >
                {el.title}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export const ChangeLanguageDesktop = onClickOutside(_ChangeLanguageDesktop);
