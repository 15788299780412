import image1 from '@assets/images/services/1.png';
import image2 from '@assets/images/services/2.jpg';
import image3 from '@assets/images/services/3.jpg';
import image4 from '@assets/images/services/4.jpg';

export const cards = [
  {
    titleKey: 'DINNER',
    subtitleKey: 'SHOW',
    descriptionKey: 'card1',
    src: image1,
  },
  {
    titleKey: 'GO-GO',
    subtitleKey: 'DANCE',
    descriptionKey: 'card2',
    src: image2,
  },
  {
    titleKey: 'HOSTESS',
    descriptionKey: 'card3',
    src: image3,
  },
  {
    titleKey: 'SUPPLEMENTARY',
    descriptionKey: 'card4',
    src: image4,
  },
];
