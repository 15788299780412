import { useCallback, useRef } from 'react';
import ImageGallery from 'react-image-gallery';

import { Controls } from '@sharedComponents/Controls';

import { useImages } from '@components/Gallery/hooks/useImages';

import closeIcon from '@assets/images/nav/closeBurgerMenu.svg';

import './index.scss';

export const FullScreenGallery = ({
  activeFullScreenPhotoIndex,
  setActiveFullScreenPhotoIndex,
}) => {
  const sliderRef = useRef(null);
  const { images } = useImages();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef?.current?.slideToIndex(sliderRef?.current?.getCurrentIndex() - 1);

    // eslint-disable-next-line
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef?.current?.slideToIndex(sliderRef?.current?.getCurrentIndex() + 1);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="FullScreenGallery">
      <div className="customContainer">
        <ImageGallery
          items={images}
          ref={sliderRef}
          showNav={false}
          showPlayButton={false}
          showFullscreenButton={false}
          startIndex={activeFullScreenPhotoIndex}
          renderCustomControls={() => (
            <div className="controlsContainer">
              <Controls
                array={images}
                handlePrev={handlePrev}
                handleNext={handleNext}
                currentIndex={sliderRef?.current?.getCurrentIndex()}
              />
            </div>
          )}
        />
      </div>

      <img
        className="closeIcon"
        src={closeIcon}
        alt="Close Fullscreen"
        onClick={() => setActiveFullScreenPhotoIndex(null)}
      />
    </div>
  );
};
