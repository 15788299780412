import onClickOutside from 'react-onclickoutside';
import { Component } from 'react';

import language from '@assets/images/nav/language.svg';

import './index.scss';

class _ChangeLanguageMobile extends Component {
  handleClickOutside = () => this.props.setIsChangeLanguagePopupVisible(false);

  render() {
    const {
      setIsChangeLanguagePopupVisible,
      checkCurrentLanguage,
      isChangeLanguagePopupVisible,
      languages,
    } = this.props;

    return (
      <div className="ChangeLanguageMobile">
        <div className="mobileLanguagesContainer">
          <div onClick={() => setIsChangeLanguagePopupVisible(prev => !prev)}>
            {checkCurrentLanguage()}
          </div>

          <img src={language} alt="Language" />

          {isChangeLanguagePopupVisible && (
            <div className="languages">
              {languages.map(el => (
                <div
                  key={el.title}
                  className={`lang${el.isCurrent ? ' activeLanguage' : ' inactiveLanguage'}`}
                  onClick={() => {
                    el.onClick();
                    setIsChangeLanguagePopupVisible(false);
                  }}
                >
                  {el.title}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const ChangeLanguageMobile = onClickOutside(_ChangeLanguageMobile);
