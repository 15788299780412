import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { CURRENT_LANGUAGE, EN, RU, UK } from '@localization/extra/constants';
import { possibleUserLanguages } from '@localization/extra/possibleUserLanguages';
import { resources } from '@localization/extra/resources';

const currentLanguage = localStorage.getItem(CURRENT_LANGUAGE);
const userLanguage = navigator.language;

let initLanguage = '';

if (!isSecureContext) {
  switch (userLanguage) {
    case possibleUserLanguages.RUSSIAN_1:
      initLanguage = RU;
      break;
    case possibleUserLanguages.RUSSIAN_2:
      initLanguage = RU;
      break;
    case possibleUserLanguages.RUSSIAN_3:
      initLanguage = RU;
      break;
    case possibleUserLanguages.RUSSIAN_4:
      initLanguage = RU;
      break;
    case possibleUserLanguages.UKRAINIAN_1:
      initLanguage = UK;
      break;
    case possibleUserLanguages.UKRAINIAN_2:
      initLanguage = UK;
      break;
    default:
      initLanguage = EN;
  }
} else {
  initLanguage = currentLanguage;
}

i18n.use(initReactI18next).init({
  lng: initLanguage,
  fallbackLng: EN,
  debug: true,
  resources,
  react: {
    useSuspense: false,
  },
});
