import image9 from '@assets/images/gallery/9.png';
import image10 from '@assets/images/gallery/10.png';
import image11 from '@assets/images/gallery/11.png';
import image12 from '@assets/images/gallery/12.png';
import image13 from '@assets/images/gallery/13.png';
import image14 from '@assets/images/gallery/14.png';
import image15 from '@assets/images/gallery/15.png';
import image16 from '@assets/images/gallery/16.png';
import image17 from '@assets/images/gallery/17.png';
import image18 from '@assets/images/gallery/18.png';
import image19 from '@assets/images/gallery/19.png';
import image20 from '@assets/images/gallery/20.png';
import image21 from '@assets/images/gallery/21.jpg';
import image22 from '@assets/images/gallery/22.png';

import './index.scss';

export const GridPhotosMore = ({ setActiveFullScreenPhotoIndex }) => (
  <div className="GridPhotosMore">
    <div onClick={() => setActiveFullScreenPhotoIndex(8)}>
      <img src={image9} alt="Galley 9" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(9)}>
      <img src={image10} alt="Galley 10" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(10)}>
      <img src={image11} alt="Galley 11" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(11)}>
      <img src={image12} alt="Galley 12" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(12)}>
      <img src={image13} alt="Galley 13" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(13)}>
      <img src={image14} alt="Galley 14" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(14)}>
      <img src={image15} alt="Galley 15" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(15)}>
      <img src={image16} alt="Galley 16" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(16)}>
      <img src={image17} alt="Galley 17" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(17)}>
      <img src={image18} alt="Galley 18" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(18)}>
      <img src={image19} alt="Galley 19" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(19)}>
      <img src={image20} alt="Galley 20" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(20)}>
      <img src={image21} alt="Galley 21" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(21)}>
      <img src={image22} alt="Galley 22" />
    </div>
  </div>
);
