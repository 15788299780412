import { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';

import { AboutUs } from '@components/AboutUs';
import { Gallery } from '@components/Gallery';
import { Nav } from '@components/Nav';
import { Services } from '@components/Services';
import { Slider } from '@components/Slider';
import { Footer } from '@components/Footer';
import { FullScreenGallery } from '@components/FullScreenGallery';

import { useResolutions } from '@hooks/useResolutions';

import { ReactComponent as ToTopIcon } from '@assets/images/global/scrollToTop.svg';

function App() {
  const { windowWidth } = useResolutions();
  const [activeFullScreenPhotoIndex, setActiveFullScreenPhotoIndex] =
    useState(null);
  const [visibleToTopBtn, setVisibleToTopBtn] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1000) {
      setVisibleToTopBtn(true);
    } else if (scrolled <= 1000) {
      setVisibleToTopBtn(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <>
      <Nav scrollToTop={scrollToTop} />

      <div className="App">
        <Fade delay={100} triggerOnce>
          <Slider />

          <AboutUs />

          <Services />

          <Gallery
            activeFullScreenPhotoIndex={activeFullScreenPhotoIndex}
            setActiveFullScreenPhotoIndex={setActiveFullScreenPhotoIndex}
          />
        </Fade>

        <Footer scrollToTop={scrollToTop} />
      </div>

      {typeof activeFullScreenPhotoIndex === 'number' && (
        <FullScreenGallery
          activeFullScreenPhotoIndex={activeFullScreenPhotoIndex}
          setActiveFullScreenPhotoIndex={setActiveFullScreenPhotoIndex}
        />
      )}

      <div
        className="scrollToTopContainer"
        style={{
          ...(windowWidth >= 1025 &&
            visibleToTopBtn &&
            typeof activeFullScreenPhotoIndex !== 'number' && {
              opacity: 1,
              marginRight: 0,
            }),
        }}
      >
        <ToTopIcon onClick={scrollToTop} />
      </div>
    </>
  );
}

export default App;
