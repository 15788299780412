export const checkPaddingBottom = (windowWidth, textHeight) => {
  if (windowWidth >= 1024 && windowWidth <= 1099) {
    return textHeight + 130;
  }

  if (windowWidth >= 1100 && windowWidth <= 1199) {
    return textHeight + 110;
  }

  if (windowWidth >= 1200 && windowWidth <= 1299) {
    return textHeight + 80;
  }

  if (windowWidth >= 1300 && windowWidth <= 1399) {
    return textHeight + 60;
  }

  if (windowWidth >= 1400 && windowWidth <= 1499) {
    return textHeight + 40;
  }

  if (windowWidth >= 1500 && windowWidth <= 1599) {
    return textHeight - 20;
  }

  if (windowWidth >= 1600 && windowWidth <= 1699) {
    return textHeight - 40;
  }

  if (windowWidth >= 1700 && windowWidth <= 1799) {
    return textHeight - 60;
  }

  if (windowWidth >= 1800 && windowWidth <= 1899) {
    return textHeight - 100;
  }

  if (windowWidth >= 1900 && windowWidth <= 1999) {
    return textHeight - 130;
  }

  if (windowWidth >= 2000 && windowWidth <= 2099) {
    return textHeight - 120;
  }

  if (windowWidth >= 2100 && windowWidth <= 2199) {
    return textHeight - 160;
  }

  if (windowWidth >= 2200 && windowWidth <= 2599) {
    return textHeight - 230;
  }

  if (windowWidth >= 2600 && windowWidth <= 2999) {
    return textHeight - 340;
  }

  if (windowWidth >= 3000 && windowWidth <= 3399) {
    return textHeight - 380;
  }

  if (windowWidth >= 3400 && windowWidth <= 3799) {
    return textHeight - 400;
  }

  return textHeight - 500;
};
