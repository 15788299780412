import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Photos } from './components/Photos';

import { useResolutions } from '@hooks/useResolutions';

import { checkPaddingBottom } from './extra/checkPaddingBottom';
import { IDs } from '@extra/IDs';

import { ReactComponent as WhatsAppIcon } from '@assets/images/global/whatsapp.svg';

import './index.scss';

export const AboutUs = () => {
  const { t } = useTranslation();
  const [textHeight, setTextHeight] = useState(0);
  const textRef = useRef(null);
  const { windowWidth } = useResolutions();

  // !important: do not change!
  // eslint-disable-next-line
  useEffect(() => {
    setTextHeight(textRef?.current?.clientHeight);
  });

  return (
    <main
      className="AboutUs"
      id={IDs.ABOUT_US}
      style={{
        padding: `200px 130px ${checkPaddingBottom(windowWidth, textHeight)}px 130px`,
      }}
    >
      <div className="backgroundBlur" />

      <div className="mystery">MYSTERY</div>

      <Photos />

      <div className="about">
        <div>{t('aboutUs.ABOUT')}</div>

        <div>{t('aboutUs.MYSTERY')}</div>

        <div>{t('aboutUs.MOTION')}</div>
      </div>

      <div className="textContainer">
        <div className="text" ref={textRef}>
          <p>{t('aboutUs.description1')}</p>
          <p>{t('aboutUs.description2')}</p>
          <p>{t('aboutUs.description3')}</p>

          <p>{t('aboutUs.descriptionList')}</p>
          <ul itemType="circle">
            <li>{t('aboutUs.Weddings')}</li>
            <li>{t('aboutUs.Birthdays')}</li>
            <li>{t('aboutUs.CorporateEvents')}</li>
            <li>{t('aboutUs.PrivateParties')}</li>
            <li>{t('aboutUs.FilmingInAVideoClips')}</li>
          </ul>

          <p>{t('aboutUs.descriptionEnd')}</p>

          <div className="callContainer">
            <a
              href="https://wa.me/971585599246"
              target="_blank"
              rel="noreferrer"
            >
              <div className="call">
                {t('aboutUs.Support')}

                <WhatsAppIcon className="whatsappIcon" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};
