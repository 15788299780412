import { useResolutions } from '@hooks/useResolutions';

import image1Original from '@assets/images/gallery/1Original.png';
import image2Original from '@assets/images/gallery/2Original.png';
import image3Original from '@assets/images/gallery/3Original.jpg';
import image4Original from '@assets/images/gallery/4Original.jpg';
import image5Original from '@assets/images/gallery/5Original.png';
import image6Original from '@assets/images/gallery/6Original.jpg';
import image7Original from '@assets/images/gallery/7Original.png';
import image8Original from '@assets/images/gallery/8Original.jpg';
import image9Original from '@assets/images/gallery/9Original.jpg';
import image10Original from '@assets/images/gallery/10Original.png';
import image11Original from '@assets/images/gallery/11Original.jpg';
import image12Original from '@assets/images/gallery/12Original.jpg';
import image13Original from '@assets/images/gallery/13Original.jpg';
import image14Original from '@assets/images/gallery/14Original.jpg';
import image15Original from '@assets/images/gallery/15Original.jpg';
import image16Original from '@assets/images/gallery/16Original.jpg';
import image17Original from '@assets/images/gallery/17Original.jpg';
import image18Original from '@assets/images/gallery/18Original.jpg';
import image19Original from '@assets/images/gallery/19Original.jpg';
import image20Original from '@assets/images/gallery/20Original.jpg';
import image21Original from '@assets/images/gallery/21Original.jpg';
import image22Original from '@assets/images/gallery/22Original.jpg';
import image1Thumb from '@assets/images/gallery/1Thumb.jpg';
import image2Thumb from '@assets/images/gallery/2Thumb.jpg';
import image3Thumb from '@assets/images/gallery/3Thumb.jpg';
import image4Thumb from '@assets/images/gallery/4Thumb.jpg';
import image5Thumb from '@assets/images/gallery/5Thumb.jpg';
import image6Thumb from '@assets/images/gallery/6Thumb.jpg';
import image7Thumb from '@assets/images/gallery/7Thumb.jpg';
import image8Thumb from '@assets/images/gallery/8Thumb.jpg';
import image9Thumb from '@assets/images/gallery/9Thumb.jpg';
import image10Thumb from '@assets/images/gallery/10Thumb.jpg';
import image11Thumb from '@assets/images/gallery/11Thumb.jpg';
import image12Thumb from '@assets/images/gallery/12Thumb.jpg';
import image13Thumb from '@assets/images/gallery/13Thumb.jpg';
import image14Thumb from '@assets/images/gallery/14Thumb.jpg';
import image15Thumb from '@assets/images/gallery/15Thumb.jpg';
import image16Thumb from '@assets/images/gallery/16Thumb.jpg';
import image17Thumb from '@assets/images/gallery/17Thumb.jpg';
import image18Thumb from '@assets/images/gallery/18Thumb.jpg';
import image19Thumb from '@assets/images/gallery/19Thumb.jpg';
import image20Thumb from '@assets/images/gallery/20Thumb.jpg';
import image21Thumb from '@assets/images/gallery/21Thumb.jpg';
import image22Thumb from '@assets/images/gallery/22Thumb.jpg';

// фотографии заресайзены вручную 853 × 1280

export const useImages = () => {
  const { windowHeight, windowWidth } = useResolutions();

  const edgeSize = windowWidth >= 1000 ? 110 : 50;

  const checkSizeDifferences = () => {
    if (windowWidth <= 430) {
      return windowHeight - 140;
    } else {
      return windowHeight - 200;
    }
  };

  // const checkSizeDifferences() = windowHeight - 200;

  const images = [
    {
      original: image1Original,
      thumbnail: image1Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image2Original,
      thumbnail: image2Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image3Original,
      thumbnail: image3Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image4Original,
      thumbnail: image4Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image5Original,
      thumbnail: image5Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image6Original,
      thumbnail: image6Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image7Original,
      thumbnail: image7Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image8Original,
      thumbnail: image8Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image9Original,
      thumbnail: image9Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image10Original,
      thumbnail: image10Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image11Original,
      thumbnail: image11Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image12Original,
      thumbnail: image12Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image13Original,
      thumbnail: image13Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image14Original,
      thumbnail: image14Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image15Original,
      thumbnail: image15Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image16Original,
      thumbnail: image16Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image17Original,
      thumbnail: image17Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image18Original,
      thumbnail: image18Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image19Original,
      thumbnail: image19Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image20Original,
      thumbnail: image20Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image21Original,
      thumbnail: image21Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
    {
      original: image22Original,
      thumbnail: image22Thumb,
      thumbnailWidth: edgeSize,
      thumbnailHeight: edgeSize,
      originalHeight: checkSizeDifferences(),
    },
  ];

  return { images };
};
