import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import '@localization/index';

import 'react-image-gallery/styles/css/image-gallery.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'react-activity/dist/library.css';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
