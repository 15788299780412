import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Controls } from '@sharedComponents/Controls';

import { cards } from '@components/Services/extra/cards';
import { IDs } from '@extra/IDs';
import { useResolutions } from '@hooks/useResolutions';

import { ReactComponent as WhatsappIcon } from '@assets/images/global/whatsapp.svg';

import './index.scss';

export const Services = ({ servicesRef }) => {
  const { t } = useTranslation();
  const sliderRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const { windowWidth } = useResolutions();

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();

    // eslint-disable-next-line
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();

    // eslint-disable-next-line
  }, []);

  const checkSlidesPerView = () => {
    if (windowWidth <= 901) {
      return 1;
    }

    if (windowWidth <= 1532) {
      return 2;
    }

    return 3;
  };

  return (
    <div className="Services" id={IDs.SERVICES} ref={servicesRef}>
      <div className="services">
        <div>{t('services.SERVICES')}</div>

        <div>
          <Controls
            array={cards}
            handleNext={handleNext}
            handlePrev={handlePrev}
            currentIndex={currentSlideIndex}
          />
        </div>
      </div>

      <div className="serviceSlicerContainer">
        <Swiper
          slidesPerView={checkSlidesPerView()}
          spaceBetween={24}
          grabCursor={false}
          loop
          ref={sliderRef}
        >
          {cards.map((el, i) => (
            <SwiperSlide key={i}>
              {({ isActive }) => {
                if (isActive) {
                  setCurrentSlideIndex(i);
                }

                return (
                  <div className="cardImageContainer">
                    <div className="cardTitle">
                      {t(`services.${el.titleKey}`)}

                      {el.subtitleKey && (
                        <div className="cardSubtitle">
                          {t(`services.${el.subtitleKey}`)}
                        </div>
                      )}
                    </div>

                    <div className="cardDescription">
                      {t(`services.${el.descriptionKey}`)}
                    </div>

                    <a
                      className="consultation"
                      href="https://wa.me/971585599246"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('services.Consultation')}

                      <WhatsappIcon className="whatsappIcon" />
                    </a>

                    <div className="cardSubBackground" />

                    <img
                      src={el.src}
                      alt={`Services ${i}`}
                      className="cardImage"
                    />
                  </div>
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="servicesBackground">SERVICES</div>
    </div>
  );
};
