export const possibleUserLanguages = {
  UKRAINIAN_1: 'uk-UA',
  UKRAINIAN_2: 'uk',
  RUSSIAN_1: 'ru-UA',
  RUSSIAN_2: 'ru',
  RUSSIAN_3: 'ru-BY',
  RUSSIAN_4: 'ru-MD',
  // ARABIC_1: 'ar',
  // ARABIC_2: 'ar-001',
  // ARABIC_3: 'ar-AE',
  // ARABIC_4: 'ar-AE',
  // ARABIC_5: 'ar-BH',
  // ARABIC_6: 'ar-DJ',
  // ARABIC_7: 'ar-DZ',
  // ARABIC_8: 'ar-EG',
  // ARABIC_9: 'ar-EH',
  // ARABIC_10: 'ar-ER',
  // ARABIC_11: 'ar-IL',
  // ARABIC_12: 'ar-IQ',
  // ARABIC_13: 'ar-JO',
  // ARABIC_14: 'ar-KM',
  // ARABIC_15: 'ar-KW',
  // ARABIC_16: 'ar-LB',
  // ARABIC_17: 'ar-LY',
  // ARABIC_18: 'ar-MA',
  // ARABIC_19: 'ar-MR',
  // ARABIC_20: 'ar-OM',
  // ARABIC_21: 'ar-PS',
  // ARABIC_22: 'ar-QA',
  // ARABIC_23: 'ar-SA',
  // ARABIC_24: 'ar-SD',
  // ARABIC_25: 'ar-SO',
  // ARABIC_26: 'ar-SS',
  // ARABIC_27: 'ar-SY',
  // ARABIC_28: 'ar-TD',
  // ARABIC_29: 'ar-TN',
  // ARABIC_30: 'ar-YE',
};
