import { useEffect, useState } from 'react';

export const useResolutions = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isTabletLandscape = windowWidth <= 1024;
  const isTabletPortrait = windowWidth <= 768;
  const isMobile = windowWidth <= 430;

  return {
    windowWidth,
    windowHeight,
    isTabletLandscape,
    isTabletPortrait,
    isMobile,
  };
};
