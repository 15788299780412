import { photos } from '@components/AboutUs/extra/photos';

import './index.scss';

export const Photos = () => (
  <div className="Photos">
    {photos.map((el, i) => (
      <div key={i} className="imageContainer">
        <img src={el} alt={'Gallery ' + i} />

        {i === 2 && <div className="cover" />}
      </div>
    ))}
  </div>
);
