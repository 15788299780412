import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu';

import { ChangeLanguageDesktop } from '@components/Nav/components/ChangeLanguageDesktop';
import { ChangeLanguageMobile } from '@components/Nav/components/ChangeLanguageMobile';

import { links } from '@components/Nav/extra/links';
import { useResolutions } from '@hooks/useResolutions';

import logo from '@assets/images/nav/logo.svg';
import _closeBurgerMenu from '@assets/images/nav/closeBurgerMenu.svg';
import { ReactComponent as WhatsappIcon } from '@assets/images/global/whatsapp.svg';
import { ReactComponent as BurgerIcon } from '@assets/images/nav/burger.svg';
import { ReactComponent as InstagramIcon } from '@assets/images/nav/instagram.svg';

import { CURRENT_LANGUAGE, EN, RU, UK } from '@localization/extra/constants';

import './index.scss';

export const Nav = ({ scrollToTop }) => {
  const { t, i18n } = useTranslation();
  const [isChangeLanguagePopupVisible, setIsChangeLanguagePopupVisible] =
    useState(false);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('home');
  const [isScrolled, setIsScrolled] = useState(false);

  const { windowWidth, isMobile } = useResolutions();

  const openBurgerMenu = () => setIsBurgerMenuOpen(prev => true);

  const closeBurgerMenu = () => setIsBurgerMenuOpen(prev => false);

  const changeLanguage = async lng => {
    await i18n.changeLanguage(lng);
    localStorage.setItem(CURRENT_LANGUAGE, lng);
  };

  const languages = [
    {
      title: t('nav.EN'),
      onClick: () => changeLanguage(EN),
      isCurrent: i18n.language === EN,
    },
    {
      title: t('nav.RU'),
      onClick: () => changeLanguage(RU),
      isCurrent: i18n.language === RU,
    },
    {
      title: t('nav.UK'),
      onClick: () => changeLanguage(UK),
      isCurrent: i18n.language === UK,
    },
  ];

  const checkCurrentLanguage = () => {
    switch (i18n.language) {
      case EN:
        return 'EN';
      case RU:
        return 'RU';
      case UK:
        return 'UKR';
      default:
        return 'EN';
    }
  };

  const determineActiveSection = () => {
    for (let i = links.length - 1; i >= 0; i--) {
      const section = document.getElementById(links[i].to);

      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 800 && rect.bottom >= 120) {
          setActiveLink(links[i].to);
          break;
        }
      }
    }
  };

  const renderMenuList = () =>
    links.map(el => (
      <li
        key={el.titleKey}
        onClick={() => {
          setIsBurgerMenuOpen(false);
          setActiveLink(el.to);
        }}
      >
        <a className={activeLink === el.to ? 'active' : ''} href={`/#${el.to}`}>
          {t(el.titleKey)}
        </a>
      </li>
    ));

  useEffect(() => {
    if (isBurgerMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isBurgerMenuOpen]);

  //hide/show header on scroll
  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setIsScrolled(scrollY > lastScrollY);

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [isScrolled]);
  //end

  //scrollspy
  useEffect(() => {
    window.addEventListener('scroll', determineActiveSection);
    return () => window.removeEventListener('scroll', determineActiveSection);
  }, []);
  //end

  return (
    <>
      <nav className={`Nav ${isScrolled ? 'scrolled' : ''}`}>
        <div className="leftSide">
          <img src={logo} alt="Logo" onClick={scrollToTop} />

          <ul className="desktopList">{renderMenuList()}</ul>
        </div>

        <div className="rightSide">
          <a
            className="instagramContainer"
            href="https://www.instagram.com/mystery_motion/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="instagramContainer">
              <InstagramIcon className="instagramIcon" />
            </div>
          </a>

          <a href="https://wa.me/971585599246" target="_blank" rel="noreferrer">
            <div className="contactUs">
              {t('nav.Support')}

              <WhatsappIcon className="whatsappIcon" />
            </div>
          </a>

          {windowWidth > 1024 && (
            <ChangeLanguageDesktop
              setIsChangeLanguagePopupVisible={setIsChangeLanguagePopupVisible}
              checkCurrentLanguage={checkCurrentLanguage}
              isChangeLanguagePopupVisible={isChangeLanguagePopupVisible}
              languages={languages}
            />
          )}
        </div>

        <BurgerIcon className="burgerMenu" onClick={openBurgerMenu} />
      </nav>

      {windowWidth <= 1024 && (
        <Menu
          isOpen={isBurgerMenuOpen}
          onOpen={openBurgerMenu}
          onClose={closeBurgerMenu}
          right
          width={isMobile ? '100%' : 300}
        >
          <div className="mobileMenuContainer">
            <div>
              <ul className="mobileList">{renderMenuList()}</ul>

              <ChangeLanguageMobile
                setIsChangeLanguagePopupVisible={
                  setIsChangeLanguagePopupVisible
                }
                checkCurrentLanguage={checkCurrentLanguage}
                isChangeLanguagePopupVisible={isChangeLanguagePopupVisible}
                languages={languages}
              />
            </div>

            <div className="supportMobileContainer">
              <a
                className="instagramContainer"
                href="https://www.instagram.com/mystery_motion/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>

              <a
                className="contactUs"
                href="https://wa.me/971585599246"
                target="_blank"
                rel="noreferrer"
              >
                {t('nav.Support')}

                <WhatsappIcon className="whatsappIcon" />
              </a>
            </div>
          </div>

          <div className="closeBurgerMenu" onClick={closeBurgerMenu}>
            <img src={_closeBurgerMenu} alt="Close Burger Menu" />
          </div>
        </Menu>
      )}
    </>
  );
};
