import image1 from '@assets/images/gallery/1.jpg';
import image2 from '@assets/images/gallery/2.png';
import image3 from '@assets/images/gallery/3.jpg';
import image4 from '@assets/images/gallery/4.png';
import image5 from '@assets/images/gallery/5.jpg';
import image6 from '@assets/images/gallery/6.png';
import image7 from '@assets/images/gallery/7.png';
import image8 from '@assets/images/gallery/8.png';

import './index.scss';

export const GridPhotos = ({ setActiveFullScreenPhotoIndex }) => (
  <div className="GridPhotos">
    <div onClick={() => setActiveFullScreenPhotoIndex(0)}>
      <img src={image1} alt="Galley 1" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(1)}>
      <img src={image2} alt="Galley 2" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(2)}>
      <img src={image3} alt="Galley 3" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(3)}>
      <img src={image4} alt="Galley 4" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(4)}>
      <img src={image5} alt="Galley 5" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(5)}>
      <img src={image6} alt="Galley 6" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(6)}>
      <img src={image7} alt="Galley 7" />
    </div>

    <div onClick={() => setActiveFullScreenPhotoIndex(7)}>
      <img src={image8} alt="Galley 8" />
    </div>
  </div>
);
