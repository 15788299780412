import { IDs } from '@extra/IDs';

export const links = [
  {
    titleKey: '',
    to: `${IDs.HOME}`,
  },
  {
    titleKey: 'nav.AboutUs',
    to: `${IDs.ABOUT_US}`,
  },
  {
    titleKey: 'nav.Services',
    to: `${IDs.SERVICES}`,
  },
  {
    titleKey: 'nav.Gallery',
    to: `${IDs.GALLERY}`,
  },
  {
    titleKey: 'nav.ContactUs',
    to: `${IDs.CONTACT_US}`,
  },
];
